export const addLink = ({
  pos = document.head,
  href,
  rel = 'stylesheet',
  insertBefore,
}) => {
  if (!pos.querySelector('link[href*="' + href + '"]')) {
    const link = document.createElement('link')
    link.setAttribute('rel', rel)
    link.setAttribute('href', href)
    if (insertBefore) {
      pos.insertBefore(link, insertBefore)
    } else {
      pos.appendChild(link)
    }
  }
}

export const addScript = ({ pos = document.head, src, ...attrs }) => {
  return new Promise((resolve) => {
    if (!pos.querySelector('script[src*="' + src + '"]')) {
      const scriptElm = document.createElement('script')
      scriptElm.src = src
      for (const key in attrs) {
        scriptElm[key] = attrs[key]
      }
      pos.appendChild(scriptElm)
      scriptElm.onload = () => {
        resolve()
      }
    } else {
      // reload page if mathjax script has been loaded before as mathjax doesn't render again on vue router change
      if (src.indexOf('mathjax') > -1) {
        window.location.reload()
      }
      resolve()
    }
  })
}
